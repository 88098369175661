import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import {
  Spin,
  Modal,
  Button,
  Space,
  Input,
  Collapse,
  List,
  Typography,
  Divider,
  Select,
  Table,
  notification,
  Popconfirm,
  Tooltip,
} from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

// Redux actions
import {
  register,
  verifyEmail,
  login,
  logout,
  resendVerificationEmail,
} from '../redux/actions/user'

function Authenticate({ user }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [loginForm, setLoginForm] = useState(true)
  const [emailVerificationToken, setemailVerificationToken] = useState('')

  const handleSubmit = () => {
    if (email && password) {
      if (!loginForm) {
        dispatch(register({ email, password, passwordConfirm }))
      } else {
        dispatch(login({ email, password }, history))
      }
    }
  }

  const handleVerifyEmail = () => {
    if (email && emailVerificationToken) {
      dispatch(verifyEmail({ email, emailVerificationToken }, history))
    }
  }

  const handleResendVerificationEmail = () => {
    if (email) {
      dispatch(resendVerificationEmail({ email }))
    }
  }

  useEffect(() => {
    warning()
    const token = localStorage.getItem('token')
    if (token) {
      history.push('/')
    }
  }, [])

  if (user.registerSuccess) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h3>Verify your email here</h3>
          <Input
            name="Email"
            placeholder="Email"
            value={email}
            onChange={(i) => setEmail(i.target.value)}
          />
          <Input
            placeholder="Enter the code here"
            value={emailVerificationToken}
            onChange={(i) => setemailVerificationToken(i.target.value)}
          />
          <Button
            type="primary"
            onClick={handleVerifyEmail}
            loading={user.authLoading}
          >
            Verify
          </Button>
          <Button
            type="link"
            onClick={handleResendVerificationEmail}
            loading={user.authLoading}
          >
            Resend token
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3>{loginForm ? 'Login' : 'Signup'} with an account</h3>
        <Input
          name="Email"
          placeholder="Email"
          value={email}
          onChange={(i) => setEmail(i.target.value)}
        />
        <Input.Password
          placeholder="Password"
          value={password}
          onChange={(i) => setPassword(i.target.value)}
        />
        {!loginForm && (
          <Input.Password
            placeholder="Confirm Password"
            value={passwordConfirm}
            onChange={(i) => setPasswordConfirm(i.target.value)}
          />
        )}
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={user.authLoading}
        >
          {loginForm ? 'Login' : 'Sign up'}
        </Button>
        <br />
        <Button type="link" onClick={() => setLoginForm(!loginForm)}>
          {loginForm
            ? "Don't have an account? Signup here."
            : 'Already have an account? Sign in here'}{' '}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(Authenticate)

function warning() {
  Modal.warning({
    title: ' Device Identifier warning!',
    content:
      "Don't share your unique Device Identifier with anyone, unless you want to share your location with them.If you think your Device Identifer has been compromised then delete all of your locations and use new one from the android app.",
  })
}
