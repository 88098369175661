export const GET_LOACTIONS_LOADING = 'GET_LOACTIONS_LOADING'
export const STORE_LOCATION = 'STORE_LOCATION'
export const STORE_FILTERED_LOCATIONS = 'STORE_FILTEREDLOCATIONS'
export const CLEAR_FILTERED_LOCATIONS = 'CLEAR_FILTEREDLOCATIONS'
export const AUTH_LOADING = 'AUTH_LOADING'
export const SET_USER = 'SET_USER'
export const SET_REGISTER_SUCCESS = 'SET_REGISTER_SUCCESS'
export const SAVE_DEVICE_LOADING = 'SAVE_DEVICE_LOADING'
export const GET_DEVICES_LOADING = 'GET_DEVICES_LOADING'
export const DELETE_DEVICE_LOADING = 'DELETE_DEVICE_LOADING'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
