import {
  GET_LOACTIONS_LOADING,
  STORE_LOCATION,
  STORE_FILTERED_LOCATIONS,
  CLEAR_FILTERED_LOCATIONS,
  AUTH_LOADING,
  SET_USER,
  SET_REGISTER_SUCCESS,
  SAVE_DEVICE_LOADING,
  GET_DEVICES_LOADING,
  DELETE_DEVICE_LOADING,
  SET_USER_DETAILS,
} from '../types'

const initialState = {
  getLocationsLoading: false,
  location: null, // One location which is redering on map
  filteredLocation: [],
  authLoading: false,
  user: null,
  registerSuccess: false,
  saveDeviceLoading: false,
  getDevicesLoading: false,
  deleteDeviceLoading: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_LOACTIONS_LOADING:
      return { ...state, getLocationsLoading: payload }
    case STORE_LOCATION:
      return { ...state, location: payload }
    case STORE_FILTERED_LOCATIONS:
      return {
        ...state,
        filteredLocation: [...state.filteredLocation, ...payload],
      }
    case CLEAR_FILTERED_LOCATIONS:
      return {
        ...state,
        filteredLocation: [],
      }
    case AUTH_LOADING:
      return { ...state, authLoading: payload }
    case SET_REGISTER_SUCCESS:
      return { ...state, registerSuccess: payload }
    case SET_USER:
      return { ...state, user: payload }
    case SAVE_DEVICE_LOADING:
      return { ...state, saveDeviceLoading: payload }
    case GET_DEVICES_LOADING:
      return { ...state, getDevicesLoading: payload }
    case SET_USER_DETAILS:
      return { ...state, userDetails: payload }
    case DELETE_DEVICE_LOADING:
      return { ...state, deleteDeviceLoading: payload }
    default:
      return state
  }
}
