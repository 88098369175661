import React, { useState, useEffect } from 'react'
import Layout from './layout/Layout'
import {
  Spin,
  Modal,
  Button,
  Space,
  Input,
  Collapse,
  List,
  Typography,
  Divider,
  Select,
  Table,
  notification,
  Popconfirm,
  Checkbox,
  Form,
  message,
  Tooltip as TooltipAntd,
} from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useDispatch, connect } from 'react-redux'

// Redux actions
import { getSavedDevice } from '../redux/actions/user'

const { Paragraph, Text } = Typography

function AddVideo(props) {
  const dispatch = useDispatch()
  const token = localStorage.getItem('token') // for using after deleting one users location

  const [response, setResponse] = useState([])
  const [Token, setToken] = useState(null)

  const getLoc = async (token) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLoc() called')

      let data
      // Checking if previous or next button, else use Search

      data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER}/count`,
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })

      console.log(data.data)
      setResponse(data.data.totalUsers)
    } catch (err) {
      console.log(err)
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLocations()  error')
    }
  }

  const onFinish = async (values) => {
    console.log('Success:', values)
    console.log(props.user.user.token)

    const token = props.user.user.token

    message.info('Please wait, this may take a few seconds')

    const data = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/api/v1/client/addVideo`,
      data: values,
      headers: {
        Authorization: `Bearer ${token}`, // 'service_token' from Postman environment
      },
    })

    message.success('Video added successfully, please tell to refresh the page')
    console.log(data)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log(token)
    setToken(token)
    if (Token) getLoc(token)

    dispatch(getSavedDevice())
  }, [Token])

  return (
    <Layout>
      <h2>Add Video</h2>
      {/* <p>{JSON.stringify(response)}</p> */}
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Url" name="url">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Upload link
          </Button>
        </Form.Item>
      </Form>

      <h2>List of all videos ({props.user?.userDetails?.videos?.length})</h2>
      {/* <p>{JSON.stringify(props.user?.userDetails?.videos)}</p> */}
      {props.user?.userDetails?.videos?.map((video) => (
        <div
          style={{
            border: '1px solid #ccc',
            boxShadow: '0 2px 3px #ccc',
            margin: '1rem',
            padding: '1rem',
          }}
        >
          <p>{video.title}</p>
          <p>{video.url}</p>

          {video.deleted ? (
            <p style={{ color: 'red' }}>Deleted</p>
          ) : (
            <p style={{ color: 'green' }}>Not Deleted</p>
          )}

          {video.seen ? (
            <p style={{ color: 'green' }}>Seen</p>
          ) : (
            <p style={{ color: 'orange' }}>Not Seen</p>
          )}

          <Popconfirm
            title="Are you sure to delete this video?"
            onConfirm={async () => {
              const token = props.user.user.token
              const data = await axios({
                method: 'DELETE',
                url: `${process.env.REACT_APP_SERVER}/api/v1/client/deleteVideo?id=${video.id}`,
                headers: {
                  Authorization: `Bearer ${token}`, // 'service_token' from Postman environment
                },
              })
              console.log(data)
              message.success('Video deleted successfully')
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="outline" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ))}
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(AddVideo)
