import React, { useState } from 'react'
import './sider.css'
import { Link, withRouter, useHistory } from 'react-router-dom'
import { Typography, Layout, Menu, Button } from 'antd'
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { useDispatch, connect } from 'react-redux'
import { logout } from '../../redux/actions/user'

const { Sider } = Layout
const { Title } = Typography

// Redux actions

function CustomSider({ location }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useState(localStorage.getItem('theme') || 'light')[0] // default to light theme or theme from localStorage

  return (
    <Sider
      theme={theme}
      breakpoint='lg'
      collapsedWidth='0'
      onBreakpoint={(broken) => {
        // console.log(broken)
      }}
      onCollapse={(collapsed, type) => {
        // console.log(collapsed, type)
      }}
    >
      <div className='logo'>
        <Title
          level={4}
          style={{
            fontFamily: 'Rampart One',
            // color: '#F0F2F5',
            textAlign: 'center',
          }}
        >
          GPS History
        </Title>
      </div>
      <Menu
        theme={theme}
        mode='inline'
        defaultSelectedKeys={['/']}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key='/' icon={<UserOutlined />}>
          <Link to='/'>Home</Link>
        </Menu.Item>
        <Menu.Item key='/how-to' icon={<ProfileOutlined />}>
          <Link to='/how-to'> How to?</Link>
        </Menu.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            // type='primary'
            danger
            style={{ width: '40%', marginTop: 200 }}
            onClick={() => {
              dispatch(logout())
              history.push('/authenticate')
            }}
          >
            Logout
          </Button>
        </div>
      </Menu>
    </Sider>
  )
}

export default withRouter(CustomSider)
