import axios from 'axios'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { notification, message } from 'antd'

import {
  GET_LOACTIONS_LOADING,
  STORE_LOCATION,
  STORE_FILTERED_LOCATIONS,
  CLEAR_FILTERED_LOCATIONS,
  AUTH_LOADING,
  SET_USER,
  SET_REGISTER_SUCCESS,
  SAVE_DEVICE_LOADING,
  GET_DEVICES_LOADING,
  DELETE_DEVICE_LOADING,
  SET_USER_DETAILS,
} from '../types'

const openNotification = (type, message) => {
  const args = {
    message,
    description: '',
    duration: 2,
    placement: 'bottomRight',
    type,
  }
  notification.open(args)
}

export const getLoc = (obj) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLoc() called')
      const { type } = obj // if previous or next location sent
      dispatch({ type: GET_LOACTIONS_LOADING, payload: true })

      dispatch({
        type: CLEAR_FILTERED_LOCATIONS,
      })

      let data
      // Checking if previous or next button, else use Search
      if (type) {
        data = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_SERVER}/location/${
            obj.query || obj.deviceIdentifier
          }?limit=1&currentId=${getState().user.location?._id}&type=${type}`,
          headers: {
            Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
          },
        })
      } else {
        data = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_SERVER}/location/${
            obj.query || obj.deviceIdentifier
          }?limit=1`,
          headers: {
            Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
          },
        })
      }

      // console.log(data.data)
      if (data.data.locations.length === 0) {
        message.error({
          content: 'No location! ❌❌❌❌❌❌❌❌❌❌',
          duration: 20,
        })
        dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
        return
      }

      dispatch({
        type: STORE_LOCATION,
        payload: null,
      })

      dispatch({
        type: STORE_LOCATION,
        payload: {
          ...data.data.locations[0],
          reverseResponse: data.data.reverseResponse,
        },
      })

      // Add polyline if needed
      // const polyline = data.data.locations.map((el, idx) => {
      //   // console.log([el.lat, el.lon])
      //   return [el.lat, el.lon]
      // })
      // setUserLocations(polyline)

      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    } catch (err) {
      console.log(err)
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLocations()  error')
      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    }
  }
}

export const getFilteredLoc = (obj) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getFilteredLoc() called')
      dispatch({ type: GET_LOACTIONS_LOADING, payload: true })

      const today = moment().startOf('day')

      dispatch({
        type: CLEAR_FILTERED_LOCATIONS,
      })

      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER}/location/${obj.deviceIdentifier}?filter=${obj.value}&limit=100&today=${today}`,
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      if (data.data.locations.length === 0) {
        message.error({
          content: 'No location! ❌❌❌❌❌❌❌❌❌❌',
          duration: 20,
        })
        dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
        return
      }

      // console.log(data);
      if (process.env.REACT_APP_ENV === 'development') {
        console.log(data.data.totalSent)
        console.log(data.data.totalFiltered)
      }

      if (data.data.totalFiltered > data.data.totalSent)
        obj.setLoadMoreBtn(true) // Showing load more btn, because more result found then they sent the documents from backend

      const locations = data.data.locations.map((el) => {
        // console.log(moment.unix(el.timestamp).format('Do MMMM YYYY h:mm:ss A'))
        // console.log(moment(el.createdAt).format('Do MMMM YYYY h:mm:ss A'))

        return {
          ...el,
          key: el._id,
          createdAt: moment(el.createdAt).format('DD-MM-YY @ h:mm:ss A'),
          accuracy: Math.round(el.accuracy * 1000) / 1000, //round to 3 decimal places
        }
      })

      dispatch({
        type: STORE_FILTERED_LOCATIONS,
        payload: locations,
      })

      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getFilteredLoc()  error')
      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    }
  }
}

export const getFilteredLocMore = (obj) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getFilteredLocMore() called')
      dispatch({ type: GET_LOACTIONS_LOADING, payload: true })

      const today = moment().startOf('day')

      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER}/location/${
          obj.deviceIdentifier
        }?filter=${obj.filter}&limit=100&skip=${
          getState().user.filteredLocation.length
        }&today=${today}`,
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      if (
        data.data.totalFiltered >
        data.data.totalSent + getState().user.filteredLocation.length
      ) {
        obj.setLoadMoreBtn(true)
      } else obj.setLoadMoreBtn(false)

      // console.log(data);
      console.log(data.data.totalSent)
      console.log(data.data.totalFiltered)

      const locations = data.data.locations.map((el) => {
        // console.log(moment.unix(el.timestamp).format('Do MMMM YYYY h:mm:ss A'))
        // console.log(moment(el.createdAt).format('Do MMMM YYYY h:mm:ss A'))

        return {
          ...el,
          key: el._id,
          createdAt: moment(el.createdAt).format('DD-MM-YY @ h:mm:ss A'),
          accuracy: Math.round(el.accuracy * 1000) / 1000, //round to 3 decimal places
        }
      })

      dispatch({
        type: STORE_FILTERED_LOCATIONS,
        payload: locations,
      })

      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    } catch (err) {
      console.log('getFilteredLocMore()  error')
      console.log(err)
      dispatch({ type: GET_LOACTIONS_LOADING, payload: false })
    }
  }
}

// Authentication related files

export const register = (obj) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('register() called')
      dispatch({ type: AUTH_LOADING, payload: true })
      dispatch({ type: SET_REGISTER_SUCCESS, payload: false })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/signup`,
        data: obj,
      })

      // console.log(data.data)

      if (data.data.status === 'success') {
        dispatch({ type: SET_REGISTER_SUCCESS, payload: data.data.status })
      }

      dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      console.log('register()  error')
      console.log(err)
      // console.log(err.response)
      if (err?.response.data?.message) {
        alert(err?.response.data?.message)
      }
      dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const verifyEmail = (obj, history) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('verifyEmail() called')
      dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/verifyEmail`,
        data: obj,
      })

      // console.log(data.data)
      if (data.data.status === 'success') {
        dispatch({ type: SET_USER, payload: data.data })
        dispatch({ type: SET_REGISTER_SUCCESS, payload: false }) // if user logout then will not see the email verification box
        // Save user data to local storage
        localStorage.setItem('token', data.data.token)
        history.push('/') // if token not found in redux then this route will push back on this current component
      }

      dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      console.log('verifyEmail() error')
      console.log(err)
      if (err?.response.data?.message) {
        alert(err?.response.data?.message)
      }
      dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const resendVerificationEmail = (obj, history) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('resendVerificationEmail() called')
      dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/resendVerificationEmail`,
        data: obj,
      })

      // console.log(data.data)

      if (data.data.status === 'success') {
        alert(data.data.message)
      }

      dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      console.log('resendVerificationEmail() error')
      console.log(err)
      // console.log(err.response)
      if (err?.response.data?.message) {
        alert(err?.response.data?.message)
      }
      dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const login = (obj, history) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('login() called')
      dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/login`,
        data: obj,
      })

      // console.log(data.data)
      if (data.data.status === 'success') {
        dispatch({ type: SET_USER, payload: data.data })
        // Save user data to local storage
        localStorage.setItem('token', data.data.token)
        history.push('/') // if token not found in redux then this route will push back on this current component
      }

      dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      console.log('login() error')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err?.response.data?.message)
        if (
          err?.response.data?.message ===
          'Please verify your email first, or try resending verification code.'
        )
          dispatch({ type: SET_REGISTER_SUCCESS, payload: 'success' })
      }
      dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const logout = (obj) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('logout() called')
      dispatch({ type: AUTH_LOADING, payload: true })

      dispatch({ type: SET_USER, payload: null })
      localStorage.removeItem('token')

      dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      console.log('logout() error')
      console.log(err)
      dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

/**
 * @description in try:
 * - get the jwt token from localStorage
 * - ge the remember me option from localStorage
 * - get the current time and convert it to seconds
 * - if token found then decode the jwt token and
 * store the decoded data in a variable
 * - check if remember me is false then
 * check if the current time is less than
 * expired time of jwt token and call
 * setCurrentUser() otherwise logout
 * - if remember me is true then call the get
 * refresh token with old refresh token that is stored in
 * localStorage
 * ✨For local development don't call the geRefreshToken()
 * even the remember me is true to prevent bug on autosave
 * feature of vscode, it cause problem maybe
 */
export const checkForExpiredToken = () => {
  console.log('checkForExpiredToken() called')
  const token = localStorage.getItem('token')
  const currentTimeInSeconds = Date.now() / 1000
  // Decode old token and get user info
  let user = null
  if (token) {
    user = jwt_decode(token)
  }

  if (token) {
    // Check token expiration
    if (user.exp >= currentTimeInSeconds) {
      // Set user
      return setCurrentUser(token)
    }
  }
  return logout()
}

/**
 * @description In try:
 * if token found then,
 * - set the jwt token in localStorage as 'token'
 * - set the token as default header in axios instance
 * - decode the JWT token and store the decoded data in
 * Redux
 * if token not found then,
 * - Clear the localStorage
 * -remove the default header from axios
 * instance
 * - remove all the user data from redux
 * @param {token} jwt token
 */
export const setCurrentUser = (token) => {
  return async (dispatch) => {
    if (token) {
      localStorage.setItem('token', token)
      // axios.defaults.headers.common.Authorization = `Bearer ${token}`
      // console.log(jwt_decode(token));
      dispatch({
        type: SET_USER,
        payload: { token },
      })
    } else {
      // localStorage.removeItem('token');
      // localStorage.removeItem('loginRemberMe');
      localStorage.clear()

      // delete axios.defaults.headers.common.Authorization

      dispatch({
        type: SET_USER,
        payload: null,
      })
    }
  }
}

// Save a device to database
export const sendSavedDevice = (deviceIdentifier, handleDevices) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('sendSavedDevice() called')
      dispatch({ type: SAVE_DEVICE_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/saveDevice`,
        data: { deviceIdentifier },
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      if (data.data.status === 'success') {
        handleDevices.setSavedDevices([
          ...handleDevices.savedDevices,
          deviceIdentifier,
        ])
        openNotification('success', 'Device successfully saved!')
      }

      dispatch({ type: SAVE_DEVICE_LOADING, payload: false })
    } catch (err) {
      console.log('login() error')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: SAVE_DEVICE_LOADING, payload: false })
    }
  }
}

// Get devices from database
export const getSavedDevice = (handleDevices) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getSavedDevice() called')
      dispatch({ type: GET_DEVICES_LOADING, payload: true })

      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/saveDevice`,
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      if (data.data.status === 'success') {
        handleDevices?.setSavedDevices(data.data.user.deviceIdentifier)
      }

      dispatch({ type: SET_USER_DETAILS, payload: data.data.user })
      dispatch({ type: GET_DEVICES_LOADING, payload: false })
    } catch (err) {
      console.log('login() error')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: GET_DEVICES_LOADING, payload: false })
    }
  }
}

// Delete device from database
export const deleteSavedDevice = (deviceIdentifier, handleDevices) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('deleteSavedDevice() called')
      dispatch({ type: DELETE_DEVICE_LOADING, payload: true })

      const data = await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/saveDevice?deviceIdentifier=${deviceIdentifier}`,
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      if (data.data.status === 'success') {
        handleDevices.setSavedDevices(
          handleDevices.savedDevices.filter((el) => el !== deviceIdentifier)
        )
        openNotification('success', 'Device successfully deleted!')
      }

      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    } catch (err) {
      console.log('deleteSavedDevice() error')
      openNotification('error', 'Failed to delete')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    }
  }
}

// Delete video from database
export const deleteAVideo = (url) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('deleteAVideo() called')
      dispatch({ type: DELETE_DEVICE_LOADING, payload: true })

      const data = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/deleteVideo?url=${url}`,
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      openNotification('success', 'ভিডিও ডীলেট হয়েছে')

      setTimeout(() => {
        window.location.reload()
      }, 2000)

      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    } catch (err) {
      console.log('deleteAVideo() error')
      openNotification('error', 'Failed to delete')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    }
  }
}

// Delete video from database
export const updateVideoStatus = (id, boolean) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('updateVideoStatus() called')
      dispatch({ type: DELETE_DEVICE_LOADING, payload: true })

      const data = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/updateVideoStatus`,
        data: { id, boolean },
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      openNotification('success', 'ভিডিও দেখা স্ট্যাটাস পরিবর্তন হয়েছে')

      setTimeout(() => {
        window.location.reload()
      }, 1000)

      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    } catch (err) {
      console.log('updateVideoStatus() error')
      openNotification('error', 'Failed to delete')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    }
  }
}

// Delete video from database
export const updateVideoDeleteStatus = (id) => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('updateVideoDeleteStatus() called')
      dispatch({ type: DELETE_DEVICE_LOADING, payload: true })

      const data = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER}/api/v1/client/updateVideoDeleteStatus`,
        data: { id },
        headers: {
          Authorization: `Bearer ${getState().user.user.token}`, // 'service_token' from Postman environment
        },
      })

      console.log(data.data)

      openNotification('success', 'ভিডিও ডীলেট হয়েছে')

      setTimeout(() => {
        window.location.reload()
      }, 2000)

      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    } catch (err) {
      console.log('updateVideoDeleteStatus() error')
      openNotification('error', 'Failed to delete')
      console.log(err)
      if (err?.response?.data?.message) {
        alert(err.response.data.message)
      }
      dispatch({ type: DELETE_DEVICE_LOADING, payload: false })
    }
  }
}
