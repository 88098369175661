import React, { useState, useEffect } from 'react'
import Layout from './layout/Layout'
import {
  Spin,
  Modal,
  Button,
  Space,
  Input,
  Collapse,
  List,
  Typography,
  Divider,
  Select,
  Table,
  notification,
  Popconfirm,
  Tooltip as TooltipAntd,
} from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import axios from 'axios'

const { Paragraph, Text } = Typography

function RemoveLocation() {
  const token = localStorage.getItem('token') // for using after deleting one users location
  
  const [response, setResponse] = useState([])
  const [Token, setToken] = useState(null)
  
  const getLoc = async (token) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLoc() called')

      let data
      // Checking if previous or next button, else use Search

      data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER}/count`,
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })

      console.log(data.data)
      setResponse(data.data.totalUsers)
    } catch (err) {
      console.log(err)
      if (process.env.REACT_APP_ENV === 'development')
        console.log('getLocations()  error')
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log(token)
    setToken(token)
    if (Token) getLoc(token)
  }, [Token])

  return (
    <Layout>
      <h2>Remove locations</h2>
      <p>
        Press on name to Delete, make sure u uncomment the code for deleting.
      </p>
      {/* <p>{JSON.stringify(response)}</p> */}
      {response?.map((el, idx) => {
        return (
          <Button
            block
            style={{margin: 5}}
            type="primary"
            onClick={async () => {
              
              const data = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_SERVER}/removeLocation/${el._id}`,
                headers: {
                  Authorization: `Bearer ${Token}`,
                },
              })
              console.log(data.status)
              if(data.status === 204) {
                console.log('Successfully deleted')
                getLoc(token)
              }
            }}
          >
            {el._id} - {el.count}
          </Button>
        )
      })}
    </Layout>
  )
}

export default RemoveLocation
