import React from 'react'
import './layout.css'
import { Layout } from 'antd'

import Sider from '../sider/Sider'

const { Content, Footer } = Layout

function layout({ children }) {
  return (
    <Layout style={{ background: 'white', minHeight: '100vh' }}>
      <Sider />
      <Layout>
        <Content style={{ margin: '24px 16px 0' }}>{children}</Content>
        <Footer style={{ textAlign: 'center' }}>
          gpshistory.live ©2021
        </Footer>
      </Layout>
    </Layout>
  )
}

export default layout
