import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import SingleUserLocation from '../components/SingleUserLocation'
import HowToStart from '../components/HowToStart'
import RemoveLocation from '../components/RemoveLocation'
import Authenticate from '../components/Authenticate'
import AddVideo from '../components/AddVideo'

const Routes = ({ ...props }) => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={SingleUserLocation} />
        <Route exact path="/how-to" component={HowToStart} />
        <Route exact path="/removelocation" component={RemoveLocation} />
        <Route exact path="/AddVideo" component={AddVideo} />
        <Route exact path="/authenticate" component={Authenticate} />

        {/* <Route component={NotFound} /> */}
      </Switch>
    </>
  )
}

export default Routes
