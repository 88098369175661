import React, { useEffect, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip,
} from 'react-leaflet'
import { Spin, Modal, Button } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import L, { LeafletMouseEvent, Map, rectangle } from 'leaflet'

function DefaultMap() {
  const [currentLoc, setCurrentLoc] = useState(null)
  const [map, setmap] = useState(null)

  useEffect(() => {
    if (map) {
      map.locate({ setView: true, maxZoom: 16 })
      map.on('locationfound', (data) => {
        // console.log(data)
        L.circleMarker([data.latlng.lat, data.latlng.lng], {
          radius: 20,
          color: 'red',
        }).addTo(map)
      })

      // * For rectangle
      // define rectangle geographical bounds
      // let radiusMts = 20
      // let bounds = L.latLng(23.8057632, 90.2539746).toBounds(radiusMts)
      // create an orange rectangle
      // L.rectangle(bounds, { color: '#ff7800', weight: 1 }).addTo(map)
      // map.fitBounds(bounds)
    }

    // getMyLoc() // When map is created, flying to device current location if found
  }, [map])

  // Get location based on browser location
  const getMyLoc = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition)
    }
    function getPosition(position) {
      console.log(position.coords.latitude, position.coords.longitude)
      // console.log(currentLoc)
      if (map) {
        map.flyTo([position.coords.latitude * 1, position.coords.longitude * 1]) // Fly to found location, if previous location is far.
      }
      setCurrentLoc([
        position.coords.latitude * 1,
        position.coords.longitude * 1,
      ])
    }
  }
  return (
    <>
      <MapContainer
        // style={{ border: '1px solid red' }}
        center={[23.8103, 90.4125]} // Dhaka
        whenCreated={setmap}
        zoom={6}
      >
        <TileLayer
          attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
      <Button
        onClick={() => {
          getMyLoc()
        }}
      >
        <EnvironmentOutlined />
      </Button>
    </>
  )
}

export default DefaultMap
