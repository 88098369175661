import React from 'react'
import { Provider } from 'react-redux'
import './App.css'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes/Routes'

import store from './redux/store'
import { checkForExpiredToken } from './redux/actions/user'

store.dispatch(checkForExpiredToken())

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route component={Routes} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
